import gql from 'graphql-tag';

export const UPDATE_APPLICATION_STATUS_AND_DEADLINE = gql`
  mutation UpdateApplicationStatusAndDeadline(
    $decisionStatus: String
    $decisionDeadline: String
    $StudentApplicationId: Int!
  ) {
    updateApplicationStatusAndDeadline(
      input: {
        decisionStatus: $decisionStatus
        decisionDeadline: $decisionDeadline
        StudentApplicationId: $StudentApplicationId
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_APPLICATION_DEADLINE = gql`
  mutation removeDeadlineFromApplication($deadlineId: Int!) {
    removeNewDeadlineFromApplication(input: { deadlineId: $deadlineId }) {
      status
    }
  }
`;

export const ADD_NEW_STUDENT_APPLICATION_DEADLINE = gql`
  mutation addNewDeadlinesToStudentApplication(
    $studentApplicationId: Int!
    $deadlineName: String!
    $deadlineDate: String!
  ) {
    addNewDeadlineToApplication(
      input: {
        studentApplicationId: $studentApplicationId
        deadlineName: $deadlineName
        deadlineDate: $deadlineDate
      }
    ) {
      status
    }
  }
`;
