import { FC, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Collapse,
  FormControl,
  Typography,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { isSubstring } from '../../../common/utility';

import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useStylesApplication } from './applicationStyle';
import REQUIRED_TRUE_ICON from '../../../img/required-true.svg';
import DRIVE_ICON from '../../../img/drive.png';
import FILE_ICON from '../../../img/file.svg';

import CAUTION_ICON from '../../../img/caution-icon-url.svg';
import { StudentApplicationDetailNewQuery_StudentApplication_studentApplicationEssays } from './graphql/queries/applicationsPage/__generated__/StudentApplicationDetailNewQuery';

const DeleteEssayForm = ({
  id,
  essayName,
  essayUrl,
}: {
  id: number;
  essayName: string;
  essayUrl: string;
}) => {
  const applicationClasses = useStylesApplication();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <a href={essayUrl} target="blank">
        {essayUrl.includes('google') ? (
          <Box className={applicationClasses.currentFileWidth}>
            <Tooltip title={essayName}>
              <img src={DRIVE_ICON} alt="drive" width="23px" />
            </Tooltip>
          </Box>
        ) : (
          <Box className={applicationClasses.currentFileWidth}>
            <Tooltip title={essayName}>
              <img src={FILE_ICON} alt="file" />
            </Tooltip>
          </Box>
        )}
      </a>
      {essayName && (
        <Box
          display="flex"
          alignItems="center"
          className={applicationClasses.fileActionButtons}
        ></Box>
      )}

      {!essayName && <span>No attachments added</span>}
    </Box>
  );
};

interface SupplementalEssaysProps {
  AppEssays: (StudentApplicationDetailNewQuery_StudentApplication_studentApplicationEssays | null)[];
  titleEssay: string | null;
  studentGraduted?: boolean;
}
const SupplementalEssays: FC<SupplementalEssaysProps> = ({
  AppEssays,
  titleEssay,
  studentGraduted = false,
}) => {
  const status = [
    {
      key: 'Not Started',
      value: 'notStarted',
      className: 'statusMenu notStarted',
    },
    {
      key: 'In Progress',
      value: 'inProgress',
      className: 'statusMenu inProgress',
    },
    {
      key: 'Review Ready',
      value: 'reviewReady',
      className: 'statusMenu reviewReady',
    },
    { key: 'Stuck', value: 'stuck', className: 'statusMenu stuck' },
    { key: 'Completed', value: 'complete', className: 'statusMenu Completed' },
  ];

  const applicationClasses = useStylesApplication();

  const EssayRow: FC<any> = ({ essay, titleEssay, index }) => {
    const applicationClasses = useStylesApplication();
    const [open, setOpen] = useState(false);
    let description = '';
    let longDescription: string[] = [];
    if (!isSubstring(titleEssay, 'other')) {
      if (!essay?.essayTemplate?.commonEssay) {
        description = essay?.essayTemplate?.shortDescription || '';
      }
      if (essay?.essayTemplate?.longDescription) {
        essay?.essayTemplate?.longDescription
          .split('#')
          .forEach((element: string) => {
            if (element) {
              longDescription.push(element);
            }
          });
      }
    }
    const name = isSubstring(
      essay?.essayTemplate?.shortDescription,
      'coalition'
    )
      ? 'Coalition App Essay'
      : 'Common App Essay';

    let maxWord = essay?.essayTemplate
      ? essay.essayTemplate?.lengthLimit
      : essay?.wordMax;

    let re = /\d/;
    if (
      isSubstring(maxWord?.toString(), 'words') &&
      typeof maxWord === 'string'
    ) {
      maxWord?.split(' ').forEach((str: string) => {
        if (re.test(str)) {
          maxWord = str;
          return;
        }
      });
    }
    return studentGraduted ? (
      <Grid
        key={essay.id}
        className={clsx(
          applicationClasses.applicationDetailPageTable,
          applicationClasses.applicationTableStyle
        )}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={applicationClasses.commonSelectDropdown}
        >
          <Box className="essayTableHeadingFirst">
            <FormControl>
              <Select
                disabled
                variant="outlined"
                defaultValue={essay.essayStatus}
                className={
                  status.find((item) => item.value === essay.essayStatus)
                    ?.className
                }
              >
                {status.map((item) => {
                  return (
                    <MenuItem
                      key={item.key}
                      value={item.value}
                      className={item.className}
                    >
                      {item.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Hidden only={['xl', 'lg', 'md']}>
            <Box
              className="essayTableHeadingThird requiredCheckMobile"
              display="flex"
              justifyContent="flex-end"
            >
              {!isSubstring(titleEssay, 'other') && essay?.essayTemplate ? (
                essay?.essayTemplate?.required ? (
                  <Box display="flex">
                    <img src={REQUIRED_TRUE_ICON} alt="true" />
                    <Typography variant="body2">REQUIRED</Typography>
                  </Box>
                ) : (
                  <Box className="requiredFileNot">
                    <ClearIcon />
                  </Box>
                )
              ) : (
                !isSubstring(titleEssay, 'other') && '--'
              )}
            </Box>
          </Hidden>

          <Box className="essayTableHeadingSecond essayName" display={'flex'}>
            <Box width="100%" onClick={() => setOpen(!open)}>
              <Typography className="essayName">
                {essay?.essayTemplate
                  ? titleEssay
                    ? `${
                        titleEssay === 'Personal Insight Q'
                          ? 'Personal Insight Q'
                          : `${titleEssay} `
                      }${index + 1}`
                    : name
                  : essay?.title}
              </Typography>
            </Box>
          </Box>

          <Hidden only={['xs', 'sm']}>
            <Box
              className="essayTableHeadingThird"
              textAlign="center"
              onClick={() => setOpen(!open)}
            >
              {!isSubstring(titleEssay, 'other') && essay?.essayTemplate ? (
                essay?.essayTemplate?.required ? (
                  <img src={REQUIRED_TRUE_ICON} alt="true" />
                ) : (
                  <Box className="requiredFileNot">
                    <ClearIcon />
                  </Box>
                )
              ) : (
                !isSubstring(titleEssay, 'other') && '--'
              )}
            </Box>
          </Hidden>

          <Box
            onClick={() => setOpen(!open)}
            className="essayTableHeadingFour wordMaxMobile"
          >
            <Box display={'flex'}>
              <Hidden only={['xl', 'md', 'lg']}>{'WORD MAX:'}</Hidden>
              {maxWord || '--'}
            </Box>
          </Box>

          <Box className="essayTableHeadingFive">
            {essay?.documentUrl ? (
              <Box display="flex">
                <DeleteEssayForm
                  essayName={
                    essay?.name ? essay?.name : essay?.documentName || ''
                  }
                  essayUrl={essay?.documentUrl}
                  id={
                    essay.isOtherEssay
                      ? essay.applicationEssayId
                      : essay?.id || 0
                  }
                />
              </Box>
            ) : (
              '--'
            )}
          </Box>

          <Box className="essayTableHeadingSix">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>

        <Box className="collapsePromptText" onClick={() => setOpen(!open)}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="h6">Prompt</Typography>
            <Typography variant="body1">
              {essay?.essayTemplate ? description : essay?.prompt}
              <br></br>
              {longDescription.length > 0 &&
                longDescription.map((item: string) => (
                  <>
                    {item}
                    <br></br>
                    <br></br>
                  </>
                ))}
            </Typography>
          </Collapse>
        </Box>
      </Grid>
    ) : !studentGraduted &&
      essay?.essayStatus === 'notStarted' &&
      !essay?.documentUrl &&
      (essay?.essayTemplate?.deletedAt || essay?.archivedAt) ? null : (
      <Grid
        key={essay.id}
        className={clsx(
          applicationClasses.applicationDetailPageTable,
          applicationClasses.applicationTableStyle
        )}
        style={
          !studentGraduted &&
          (essay?.essayTemplate?.deletedAt || essay?.archivedAt) &&
          (essay?.documentUrl || essay?.essayStatus !== 'notStarted')
            ? { backgroundColor: '#FFF8E4' }
            : {}
        }
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box className="essayTableHeadingFirst">
            <FormControl>
              <Select
                disabled
                variant="outlined"
                defaultValue={essay.essayStatus}
                className={
                  status.find((item) => item.value === essay.essayStatus)
                    ?.className
                }
              >
                {status.map((item) => {
                  return (
                    <MenuItem
                      key={item.key}
                      value={item.value}
                      className={item.className}
                    >
                      {item.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Hidden only={['xl', 'lg', 'md']}>
            <Box
              className="essayTableHeadingThird requiredCheckMobile"
              display="flex"
              justifyContent="flex-end"
            >
              {!isSubstring(titleEssay, 'other') &&
              essay?.essayTemplate &&
              !(essay?.essayTemplate?.deletedAt || essay?.archivedAt) ? (
                essay?.essayTemplate?.required ? (
                  <Box display="flex">
                    <img src={REQUIRED_TRUE_ICON} alt="true" />
                    <Typography variant="body2">REQUIRED</Typography>
                  </Box>
                ) : (
                  <Box className="requiredFileNot">
                    <ClearIcon />
                  </Box>
                )
              ) : (
                !isSubstring(titleEssay, 'other') && '--'
              )}
            </Box>
          </Hidden>

          <Box className="essayTableHeadingSecond essayName" display={'flex'}>
            <Box width="100%" onClick={() => setOpen(!open)}>
              <Typography className="essayName">
                {essay?.essayTemplate
                  ? titleEssay
                    ? `${
                        titleEssay === 'Personal Insight Q'
                          ? 'Personal Insight Q'
                          : `${titleEssay} `
                      }${
                        essay?.essayTemplate?.deletedAt
                          ? '(Archived)'
                          : index + 1
                      }`
                    : name
                  : `${essay?.title} ${essay?.archivedAt ? '(Archived)' : ''}`}
              </Typography>
            </Box>
          </Box>

          <Hidden only={['xs', 'sm']}>
            <Box
              className="essayTableHeadingThird"
              textAlign="center"
              onClick={() => setOpen(!open)}
            >
              {!isSubstring(titleEssay, 'other') &&
              essay?.essayTemplate &&
              !(essay?.essayTemplate?.deletedAt || essay?.archivedAt) ? (
                essay?.essayTemplate?.required ? (
                  <img src={REQUIRED_TRUE_ICON} alt="true" />
                ) : (
                  <Box className="requiredFileNot">
                    <ClearIcon />
                  </Box>
                )
              ) : (
                !isSubstring(titleEssay, 'other') && '--'
              )}
            </Box>
          </Hidden>

          <Box
            onClick={() => setOpen(!open)}
            className="essayTableHeadingFour wordMaxMobile"
          >
            <Box display={'flex'}>
              <Hidden only={['xl', 'md', 'lg']}>{'WORD MAX:'}</Hidden>
              {maxWord || '--'}
            </Box>
          </Box>

          <Box className="essayTableHeadingFive">
            {essay?.documentUrl ? (
              <Box display="flex">
                <DeleteEssayForm
                  essayName={
                    essay?.name ? essay?.name : essay?.documentName || ''
                  }
                  essayUrl={essay?.documentUrl}
                  id={
                    essay.isOtherEssay
                      ? essay.applicationEssayId
                      : essay?.id || 0
                  }
                />
              </Box>
            ) : (
              '--'
            )}
          </Box>

          <Box className="essayTableHeadingSix">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>

        <Box className="collapsePromptText" onClick={() => setOpen(!open)}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="h6">Prompt</Typography>
            <Typography variant="body1">
              {essay?.essayTemplate ? description : essay?.prompt}
              <br />
              <br />
              {essay?.archivedAt || essay?.essayTemplate?.deletedAt ? (
                <Box display={'flex'} alignItems="baseline">
                  <img src={CAUTION_ICON} alt="caution" width="15px" />
                  This prompt was archived by CollegeAdvisor.com because the
                  prompt is no longer in use for this school’s application.
                </Box>
              ) : null}
              <br></br>
              {longDescription.length > 0 &&
                longDescription.map((item: string) => (
                  <>
                    {item}
                    <br></br>
                    <br></br>
                  </>
                ))}
            </Typography>
          </Collapse>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={clsx(
          applicationClasses.applicationDetailPageTable,
          applicationClasses.headingDisplayMobile
        )}
      >
        <Box className="essayTableHeading essayTableHeadingFirst">&nbsp;</Box>
        <Box className="essayTableHeading essayTableHeadingSecond">&nbsp;</Box>
        <Box
          className="essayTableHeading essayTableHeadingThird"
          textAlign="center"
        >
          {!titleEssay?.includes('Other') && 'REQUIRED'}
        </Box>
        <Box className="essayTableHeading essayTableHeadingFour">WORD MAX</Box>
        <Box className="essayTableHeading essayTableHeadingFive">
          CURRENT FILE
        </Box>
        <Box className="essayTableHeading essayTableHeadingSix">&nbsp;</Box>
      </Box>

      <Box>
        {AppEssays?.map((essay: any, index: any) => {
          return (
            <EssayRow essay={essay} titleEssay={titleEssay} index={index} />
          );
        })}
      </Box>
    </>
  );
};
export default SupplementalEssays;
