import { useState, useContext, Fragment, FC } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import PersonIcon from '@material-ui/icons/Person';
import EDIT_ICON from '../../../img/edit-icon.svg';
import ACTIVITY_LOGGED from '../../../img/activity-logged.svg';
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
  Button,
  IconButton,
  Hidden,
  Grid,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import NOTE from '../../../img/note.svg';
import DELETE_ICON from '../../../img/delete-icon.svg';
import CLOSE_ICON from '../../../img/dialog-close-icon.svg';
import client from '../../../apollo';
import { AppContext } from '../../../contexts';
import { ADD_MEETING_CANCELLATION_REASON, DELETE_BOOKING } from './gql';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import AddReasonsModel from './AddReasonsModel';
import { bookingInfoStatuses } from '../../../constants';
import {
  canceledReason,
  convertToSpecificTimezone,
} from '../../../common/utility';
import CancelMeetingModal from './CancelMeetingModal';
import MeetingRecordProps from '../../../common/interfaces';
import MenuOptions from './MenuOptions';
import CustomTooltip from '../../../components/CustomTooltip';
import { getAllAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../../../graphql/queries/advisorBookings/__generated__/getAllAdvisorBookingsQuery';
import EditDialog from '../timeLogs/editTimelogModal/EditDialog';
import { useStyles } from './MeetingRecordStyles';

const MeetingRecord: FC<MeetingRecordProps & RouteComponentProps> = ({
  row,
  index,
  status,
  refetch,
  handlePopUpNotification,
  history,
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [isDisabledState, setIsDisabledState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openAddReasonModel, setOpenAddReasonModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>(
    row?.otherReason || ''
  );
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] =
    useState<getAllAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null>(
      null
    );

  const handleEditDialog = () => {
    setOpenEditDialog(!openEditDialog);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialoge = () => {
    setOpen(false);
  };
  const Timezone: string = user?.timezone ? (user?.timezone as string) : '';

  const startDate = row?.startTime
    ? moment.unix(row?.startTime).format('ddd MMM DD, YYYY')
    : 'N/A';

  const thirdOfJuly = new Date('2023-07-03T00:00:00Z');
  const thirdOfJulyUnixTime = Math.floor(thirdOfJuly.getTime() / 1000); // Convert to Unix timestamp
  const presentDate = Math.floor(new Date().getTime() / 1000);

  const startTime = row?.startTime
    ? moment.unix(row?.startTime).format('LT')
    : 'NA';
  const endTime = row?.endTime ? moment.unix(row?.endTime).format('LT') : 'NA';

  const student = row?.student;
  const advisors = row?.allParticipants?.length
    ? row?.allParticipants?.filter(
        (p) => !p?.roles?.includes('Student') && p?.id !== user?.id
      )
    : [];
  const mainAdv = row?.allParticipants?.find(
    (item) => item?.id === row.AdvisorId
  );
  const note = row?.note;
  const meetingDuration = row?.endTime
    ? (row?.endTime - row?.startTime) / 60
    : 0;

  const { formattedDate } = convertToSpecificTimezone(
    row?.startTime || 0,
    Timezone
  );

  const otherAdvisors = row?.allParticipants
    ?.filter(
      (participant) =>
        participant?.roles?.includes('Mentor') && participant.id !== user?.id
    )
    ?.map((advisor) => {
      return {
        label: `${advisor?.firstName || ''} ${advisor?.lastName || ''}\n(${
          advisor?.email
        })`,
        value: `${advisor?.id}`,
      };
    });

  const handleCancelMeeting = async (reason: String, other: string) => {
    try {
      if (reason === 'other' && !other) {
        handlePopUpNotification("Reason can't be empty", 'error');
      } else {
        setLoading(true);
        if (selectedRow?.id && selectedRow.AdvisorId) {
          await client.mutate({
            mutation: DELETE_BOOKING,
            variables: {
              eventId: selectedRow.id,
              AdvisorId: selectedRow.AdvisorId,
              reason: reason,
              ...(other && {
                otherReason: other,
              }),
            },
          });
          setOpenDeleteConfirmation(false);
          setSelectedRow(null);
          setReason('');
          setOtherReason('');
          handlePopUpNotification('Meeting Canceled');
          refetch && refetch();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleAddReasons = async (reason: String, other: string) => {
    try {
      if ((reason === '' && !isEdit) || (reason === 'other' && !otherReason)) {
        handlePopUpNotification("Reason can't be empty", 'error');
      } else {
        setLoading(true);
        const filterAdvisor = selectedRow?.allParticipants
          ?.filter((student) => student?.id !== selectedRow.StudentId)
          .map((participantId) => participantId?.id);
        if (selectedRow?.id && selectedRow.AdvisorId) {
          const res = await client.mutate({
            mutation: ADD_MEETING_CANCELLATION_REASON,
            variables: {
              id: selectedRow.id,
              reason: reason,
              participants: filterAdvisor,
              ...(other && {
                otherReason: other,
              }),
            },
          });
          const { status, message } =
            res.data && res.data.updateCanceledMeetingReason;
          if (status === false) {
            handlePopUpNotification(message, 'error');
          } else {
            setOpenAddReasonModel(false);
            setSelectedRow(null);
            setReason('');
            handlePopUpNotification(
              isEdit
                ? 'Reason Updated Successfully'
                : 'Reason Added Successfully'
            );
            setIsEdit(false);
            refetch && refetch();
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CancelMeetingModal
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(!openDeleteConfirmation);
          setSelectedRow(null);
          setReason('');
          setIsComplete(false);
          setOtherReason('');
        }}
        title="Cancel Meeting"
        firstButtonText="Cancel Meeting"
        secondButtonText="Keep"
        handleCancelBtn={handleCancelMeeting}
        loading={loading}
        startTime={startTime}
        startDate={startDate}
        reason={reason}
        setReason={setReason}
        isComplete={isComplete}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
      />
      <AddReasonsModel
        open={openAddReasonModel}
        onClose={() => {
          setOpenAddReasonModel(!openAddReasonModel);
          setSelectedRow(null);
          setReason(row?.canceledReason || '');
          setOtherReason(row?.otherReason || '');
        }}
        setOtherReason={setOtherReason}
        otherReason={otherReason}
        title="Meeting Cancelation Reason"
        firstButtonText="Cancel"
        secondButtonText="Save"
        handleAddReasonBtn={handleAddReasons}
        loading={loading}
        startTime={startTime}
        startDate={startDate}
        student={row?.student}
        reasonValue={row?.canceledReason}
        isEdit={isEdit}
        reason={reason}
        setReason={setReason}
      />
      <Hidden only={['sm', 'xs']}>
        <TableRow key={index}>
          <TableCell
            className="cursor"
            onClick={() => {
              if (student?.id && student) {
                history.push(
                  `/students/student-details/${student?.id}?_t=info`
                );
              }
            }}
          >
            <Box display="flex" alignItems="center">
              {student?.pictureUrl ? (
                <Avatar
                  alt="avatar"
                  src={student?.pictureUrl}
                  className={classes.smallAvatarMeeting}
                />
              ) : (
                <Box className={classes.smallAvatarEmptyMeeting}>
                  <PersonIcon />
                </Box>
              )}
            </Box>
          </TableCell>

          <TableCell
            className="cursor"
            onClick={() => {
              if (student?.id && student) {
                history.push(
                  `/students/student-details/${student?.id}?_t=info`
                );
              }
            }}
          >
            {' '}
            {`${student?.firstName} ${student?.lastName}` || ' --'}
          </TableCell>

          <TableCell>{startDate}</TableCell>

          <TableCell>
            {startTime}-{endTime} ({' '}
            {momentTz(startDate).tz(momentTz.tz.guess()).format('z')} )
          </TableCell>

          <TableCell>
            <Box display="flex" alignItems="center">
              {advisors.length
                ? advisors?.map((adv) => (
                    <>
                      {adv?.pictureUrl ? (
                        <Avatar
                          alt="avatar"
                          src={adv?.pictureUrl}
                          className={classes.smallAvatar}
                        />
                      ) : (
                        <Box className={classes.smallAvatarEmpty}>
                          <PersonIcon />
                        </Box>
                      )}
                      <Box marginRight="20px">{`${adv?.firstName} ${adv?.lastName}`}</Box>
                    </>
                  ))
                : '_ _'}
            </Box>
          </TableCell>

          <TableCell>
            {note?.length ? (
              <>
                <div>
                  <IconButton onClick={handleClickOpen}>
                    <img src={NOTE} alt="note" />
                  </IconButton>
                  <Dialog
                    open={open}
                    onClose={handleCloseDialoge}
                    className={classes.meetingDialogMain}
                  >
                    <DialogContent className={classes.meetingNotesDialog}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={1}
                      >
                        <Typography variant="h6" className="dialogHeading">
                          Meeting Note
                        </Typography>

                        <IconButton onClick={handleCloseDialoge}>
                          <img src={CLOSE_ICON} alt="close" />
                        </IconButton>
                      </Box>

                      <Typography variant="body1" className="dialogSubtitle">
                        {note}
                      </Typography>
                    </DialogContent>
                  </Dialog>
                </div>
              </>
            ) : (
              <Box style={{ padding: 12 }}>--</Box>
            )}
          </TableCell>
          {status === bookingInfoStatuses.UPCOMING && (
            <TableCell>
              <a
                href={row?.zoomLink || mainAdv?.zoomLink || ''}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </TableCell>
          )}
          {status === bookingInfoStatuses.CANCELLED &&
            (row?.cancelledByUser ? (
              <TableCell>{`${row?.cancelledByUser?.firstName} ${row?.cancelledByUser?.lastName}`}</TableCell>
            ) : (
              <TableCell>{'--'}</TableCell>
            ))}

          {status === bookingInfoStatuses.CANCELLED && row?.canceledReason ? (
            <TableCell>
              {row?.canceledReason
                ? canceledReason(row?.canceledReason)?.label
                : '--'}
              {row?.otherReason !== null && (
                <CustomTooltip message={row?.otherReason}>
                  {' '}
                  - {row?.otherReason?.substring(0, 20)}
                  {row?.otherReason.length > 20 && '...'}
                </CustomTooltip>
              )}
              <IconButton
                className="mb-1"
                onClick={() => {
                  setSelectedRow(row);
                  setOpenAddReasonModel(!openDeleteConfirmation);
                  setAnchorEl(null);
                  setIsEdit(true);
                }}
              >
                <img src={EDIT_ICON} alt="edit" />
              </IconButton>
            </TableCell>
          ) : (
            <>
              {status === bookingInfoStatuses.CANCELLED && (
                <TableCell>
                  <Button
                    onClick={() => {
                      setSelectedRow(row);
                      setOpenAddReasonModel(!openDeleteConfirmation);
                      setAnchorEl(null);
                    }}
                    className={`menu-button mb-1 ${classes.addReason}`}
                  >
                    Add Cancelation Reason
                  </Button>
                </TableCell>
              )}
            </>
          )}
          {status === bookingInfoStatuses.COMPLETED && (
            <TableCell
              width={300}
              align="center"
              className={classes.approvedDetails}
            >
              {row?.startTime &&
              row?.startTime >= thirdOfJulyUnixTime &&
              row?.startTime <= presentDate ? (
                user && row?.HoursLoggedBy?.includes(user?.id) ? (
                  (row.associatedMentorTimeLog?.MentorPayoutId &&
                    row.associatedMentorTimeLog.mentorPaymentCategory &&
                    row.associatedMentorTimeLog?.mentorPayout?.status !==
                      'pending' &&
                    row.associatedMentorTimeLog?.mentor?.stripeAccountId) ||
                  (row.associatedMentorTimeLog?.isFullTime &&
                    !user.isFullTime) ? (
                    <>
                      <img src={ACTIVITY_LOGGED} alt="check" width="15px" />
                      <span className="approvedText">Approved</span>
                      <span
                        onClick={() => {
                          setIsDisabledState(true);
                          setOpenEditDialog(!openEditDialog);
                        }}
                        className="anchorText"
                      >
                        Details
                      </span>
                    </>
                  ) : (
                    <>
                      <img src={ACTIVITY_LOGGED} alt="logged" width="15px" />
                      <span className="approvedText">
                        {row.associatedMentorTimeLog?.isFullTime
                          ? 'Approved'
                          : 'Pending'}
                      </span>
                      <span
                        onClick={() => {
                          setIsDisabledState(false);
                          setOpenEditDialog(!openEditDialog);
                        }}
                        className="anchorText"
                      >
                        Edit
                      </span>
                    </>
                  )
                ) : (
                  <Link
                    to={{
                      pathname: '/time-log/add',
                      search: `?meetingId=${row?.id}&studentId=${
                        row?.StudentId
                      }&otherAdv=${
                        row?.allParticipants && row?.allParticipants?.length > 2
                          ? true
                          : false
                      }&studentName=${
                        row?.student?.firstName + ' ' + row?.student?.lastName
                      }&studentEmail=${
                        row?.student?.email
                      }&meetingDuration=${meetingDuration}&date=${formattedDate}&initialKickOffDate=${
                        row?.student?.initialKickOffDate || ''
                      }`,
                      state: otherAdvisors,
                    }}
                    className="logActivity"
                  >
                    Log activity
                  </Link>
                )
              ) : (
                <></>
              )}
            </TableCell>
          )}

          {row?.associatedMentorTimeLog && (
            <EditDialog
              open={openEditDialog}
              item={row?.associatedMentorTimeLog}
              closeDialog={handleEditDialog}
              refetch={refetch}
              isDisabledState={isDisabledState}
              fromMyMeetingsPage={true}
            />
          )}

          {status === bookingInfoStatuses.CANCELLED && (
            <TableCell
              width={300}
              align="center"
              className={classes.approvedDetails}
              style={{ paddingRight: 50 }}
            >
              {row?.startTime &&
              row?.startTime >= thirdOfJulyUnixTime &&
              row?.startTime <= presentDate ? (
                user && row?.HoursLoggedBy?.includes(user?.id) ? (
                  (row.associatedMentorTimeLog?.MentorPayoutId &&
                    row.associatedMentorTimeLog.mentorPaymentCategory &&
                    row.associatedMentorTimeLog?.mentorPayout?.status !==
                      'pending' &&
                    row.associatedMentorTimeLog?.mentor?.stripeAccountId) ||
                  (row.associatedMentorTimeLog?.isFullTime &&
                    !user.isFullTime) ? (
                    <>
                      <img src={ACTIVITY_LOGGED} alt="check" width="15px" />
                      <span className="approvedText">Approved</span>
                      <span
                        onClick={() => {
                          setIsDisabledState(true);
                          setOpenEditDialog(!openEditDialog);
                        }}
                        className="anchorText"
                      >
                        Details
                      </span>
                    </>
                  ) : (
                    <>
                      <img src={ACTIVITY_LOGGED} alt="logged" width="15px" />
                      <span className="approvedText">
                        {row.associatedMentorTimeLog?.isFullTime
                          ? 'Approved'
                          : 'Pending'}
                      </span>
                      <span
                        onClick={() => {
                          setIsDisabledState(false);
                          setOpenEditDialog(!openEditDialog);
                        }}
                        className="anchorText"
                      >
                        Edit
                      </span>
                    </>
                  )
                ) : row?.canceledReason === 'no-show' ? (
                  <Link
                    to={{
                      pathname: '/time-log/add',
                      search: `?meetingId=${row?.id}&studentId=${
                        row?.StudentId
                      }&otherAdv=${
                        row?.allParticipants && row?.allParticipants?.length > 2
                          ? true
                          : false
                      }&studentName=${
                        row?.student?.firstName + ' ' + row?.student?.lastName
                      }&studentEmail=${
                        row?.student?.email
                      }&meetingDuration=${meetingDuration}&date=${formattedDate}&canceledMeeting=true&initialKickOffDate=${
                        row?.student?.initialKickOffDate || ''
                      }`,
                      state: otherAdvisors,
                    }}
                    className="logActivity"
                  >
                    Log activity
                  </Link>
                ) : (
                  <></>
                )
              ) : (
                ''
              )}
            </TableCell>
          )}
          {status === bookingInfoStatuses.COMPLETED && (
            <TableCell width={300}>
              <MenuOptions
                openDeleteModal={() => {
                  setOpenDeleteConfirmation(!openDeleteConfirmation);
                  setIsComplete(!isComplete);
                  setSelectedRow(row);
                }}
              />
            </TableCell>
          )}
          {status === bookingInfoStatuses.UPCOMING && (
            <TableCell>
              <IconButton
                onClick={() => {
                  setSelectedRow(row);
                  setOpenDeleteConfirmation(!openDeleteConfirmation);
                  setAnchorEl(null);
                }}
                className={
                  row?.AdvisorId !== user?.id
                    ? classes.disabledBtn
                    : 'menu-button'
                }
                disabled={row?.AdvisorId !== user?.id}
              >
                <img src={DELETE_ICON} alt="delete" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </Hidden>

      <Hidden only={['xl', 'lg', 'md']}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.mobileMeetingsView}
          onClick={() => {
            if (student?.id && student) {
              history.push(`/students/student-details/${student?.id}?_t=info`);
            }
          }}
        >
          <Box display="flex">
            <Box display="flex">
              <AvatarGroup max={2}>
                {row?.allParticipants &&
                  row?.allParticipants.map((participant, index) =>
                    participant?.pictureUrl ? (
                      <Avatar
                        key={`${index} avatar`}
                        src={participant?.pictureUrl || ''}
                        alt="advisors"
                      />
                    ) : (
                      <Box className={classes.smallAvatarEmptyMeeting}>
                        <PersonIcon />
                      </Box>
                    )
                  )}
              </AvatarGroup>
            </Box>

            <Grid>
              <Typography noWrap variant="h6" className="advisorName">
                {row?.allParticipants?.length &&
                  row?.allParticipants.map((participant, index) => (
                    <Fragment key={`desktop-${index}-name`}>
                      {participant?.firstName + ' '}
                      {participant?.lastName}
                      {row?.allParticipants &&
                      row?.allParticipants?.length - 1 != index
                        ? ', '
                        : ''}
                    </Fragment>
                  ))}
              </Typography>

              <Typography variant="body1">{startDate}</Typography>

              <Typography variant="body1" className="linkSpacing">
                {startTime}-{endTime} ({' '}
                {momentTz.tz(momentTz.tz.guess()).format('z')} )
              </Typography>

              <Typography variant="body1">
                <b>Canceled By: </b>

                {status === bookingInfoStatuses.CANCELLED &&
                row?.cancelledByUser ? (
                  <>
                    {`${row?.cancelledByUser?.firstName} ${row?.cancelledByUser?.lastName}`}
                  </>
                ) : (
                  '--'
                )}
              </Typography>

              <a
                href={row?.zoomLink || mainAdv?.zoomLink || ''}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </Grid>
          </Box>

          {status === bookingInfoStatuses.UPCOMING && (
            <IconButton
              onClick={() => {
                setSelectedRow(row);
                setOpenDeleteConfirmation(!openDeleteConfirmation);
                setAnchorEl(null);
              }}
              className={
                row?.AdvisorId !== user?.id
                  ? classes.disabledBtn
                  : 'menu-button'
              }
              disabled={row?.AdvisorId !== user?.id}
            >
              <img src={DELETE_ICON} alt="delete" />
            </IconButton>
          )}
        </Box>
      </Hidden>
    </>
  );
};
export default withRouter(MeetingRecord);
