import gql from 'graphql-tag';

/**
 * Fetch advisors available timeslots by selected duration (internally get form nylas)
 */

export const SUMMER_OPPORTUNITY_CATEGORIES = gql`
  query allSummerOpportunityCategoriesQuery(
    $slug: String
    $name: String
    $limit: Int
  ) {
    allSummerOpportunityCategories(
      input: { slug: $slug, name: $name, limit: $limit }
    ) {
      summerOpportunityCategories {
        id
        name
        slug
      }
    }
  }
`;
export const ALL_OPPORTUNITIES = gql`
  query AllSummerOpportunitiesQuery(
    $page: Int
    $limit: Int
    $type: String
    $category: String
    $metroArea: String
    $city: String
    $state: String
    $grade: String
    $searchQuery: String
    $StudentId: Int
    $cost: String
    $remote: Boolean
    $status: String
    $stipend: String
    $location: String
    $searchFromSaved: Boolean
  ) {
    allSummerOpportunities(
      input: {
        page: $page
        limit: $limit
        type: $type
        category: $category
        city: $city
        state: $state
        metroArea: $metroArea
        searchQuery: $searchQuery
        cost: $cost
        status: $status
        remote: $remote
        grade: $grade
        stipend: $stipend
        location: $location
        searchFromSaved: $searchFromSaved
        StudentId: $StudentId
      }
    ) {
      nextRecord
      summerOpportunitiesCount
      summerOpportunities {
        id
        programName
        organizationName
        landingPageUrl
        programDescription
        organizationLogo
        programCost
        programStartDate
        programEndDate
        stipendGranted
        deadlineType
        applicationDeadLine
        targetGrade
        isRemote
        createdAt
        zipCode
        type
        city
        state
        submittedByAdvisor {
          id
          firstName
          lastName
          roles
        }
        summerOpportunityRegion {
          city
          state
        }
        summerOpportunityCategory {
          name
        }
        summerOpportunityType {
          name
        }
        isBookMarked
      }
    }
  }
`;
export const FETCH_ADVISOR_AVAILABILITY_TIMESLOTS = gql`
  query AdvisorAvailableTimeslots(
    $AdvisorIds: [Int!]!
    $duration: Int!
    $date: String!
    $offset: Float!
    $monthOrDay: String!
    $studentCurrentTime: Date!
    $isUserInDSTtimeZone: Boolean!
  ) {
    advisorAvailableTimeslots(
      input: {
        AdvisorIds: $AdvisorIds
        duration: $duration
        date: $date
        offset: $offset
        monthOrDay: $monthOrDay
        studentCurrentTime: $studentCurrentTime
        isUserInDSTtimeZone: $isUserInDSTtimeZone
      }
    ) {
      time_slots {
        end
        start
        status
      }
      available_days
    }
  }
`;

export const BOOK_TIMESLOT = gql`
  mutation createNylasCalendarEvent(
    $AdvisorId: Int!
    $start_time: Int!
    $end_time: Int!
    $participants: [Participant!]!
    $note: String
  ) {
    createNylasCalendarEvent(
      input: {
        AdvisorId: $AdvisorId
        start_time: $start_time
        end_time: $end_time
        participants: $participants
        note: $note
      }
    ) {
      success
      message
    }
  }
`;

export const GET_ALL_STUDENT_MENTORS = gql`
  query getAllStudentMentors($id: Int) {
    allStudentMentors(id: $id) {
      startAt
      mentor {
        id
        firstName
        lastName
        email
        intakeYear
        highSchoolYear
        phoneNumber
        sendBirdChannelUrl
        advisorLevel
        sendbirdId
        pictureUrl
        currentCollege {
          name
        }
      }
    }
  }
`;
