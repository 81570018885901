import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import SELECT_ARROW_ICON from '../../../img/select-arrow-icon.svg';

export const useStylesApplication = makeStyles((theme: Theme) =>
  createStyles({
    addNewEssayDialog: {
      '& .MuiDialog-paper': {
        maxWidth: 628,
        width: '100%',
        borderRadius: 25,
        margin: '0px 10px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          margin: '0px 10px !important',
          borderRadius: 10,
        },

        '& .MuiDialogContent-root': {
          padding: '40px 43px 30px',

          [theme.breakpoints.down('xs')]: {
            padding: '20px 16px',
          },
        },

        '& .MuiTypography-h4': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 24,
          letterSpacing: '-0.019em',
          color: '#272929',
          marginBottom: 28,

          '& img': {
            marginLeft: 10,
          },
        },

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#272929',
          marginBottom: 4,
        },

        '& .MuiInputBase-root': {
          border: '1px solid #D4D4D4',
          borderRadius: 6,
          padding: '11px 16px',
        },

        '& .MuiInputBase-input': {
          padding: 0,
          color: '#272929',
          fontFamily: fonts.INTER,
          fontSize: 14,
          letterSpacing: '-0.006em',

          '&::placeholder': {
            color: '#A9A9A9',
          },
        },

        '& .MuiInput-underline:before, .MuiInput-underline:after': {
          display: 'none',
        },

        '& .MuiFormHelperText-root.Mui-error': {
          fontFamily: fonts.POPPINS_FONT,
        },

        '& .MuiDialogActions-root': {
          padding: '28px 0px 0px',
          justifyContent: 'flex-end',

          '& .MuiButton-root': {
            height: 40,
            fontWeight: 'bold',
            fontFamily: fonts.POPPINS_FONT,
            borderRadius: 12,
            letterSpacing: '-0.006em',
            textTransform: 'capitalize',
            boxShadow: 'none',
          },

          '& .MuiButton-outlined': {
            color: '#272929',
            minWidth: 101,
            background: Colors.WHITE_ONE,
            border: '1px solid #D4D4D4',
          },

          '& .MuiButton-contained': {
            color: Colors.WHITE_ONE,
            minWidth: 134,
            marginLeft: 16,
            background: '#2F80ED',
            border: '1px solid #2F80ED',

            [theme.breakpoints.down('xs')]: {
              minWidth: 140,
              marginLeft: 8,
            },
          },

          '& .MuiButton-label': {
            fontSize: 14,
          },
        },
      },
    },

    decisionSelectMenu: {
      textAlign: 'right',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: 20,
        paddingTop: 21,
        borderTop: `1px solid ${Colors.BLACK_THIRTEEN}`,
      },

      '& .dateText': {
        textAlign: 'right',

        [theme.breakpoints.down('xs')]: {
          textAlign: 'left',
          marginTop: 16,
        },
        '& .tooltipMobile': {
          [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
          },
        },

        '& .MuiTypography-h6': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '32px',
          letterSpacing: '-0.019em',
          color: Colors.BLACK_FOURTEEN,
          marginBottom: 2,
        },

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontWeight: 'normal',
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '-0.011em',
          color: Colors.BLACK_FOURTEEN,
        },
      },
    },

    moveAccordionApplications: {
      '& .MuiAccordionSummary-root': {
        borderBottom: 0,
      },
    },
    currentFileWidth: {
      '& a': {
        width: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    selectionDropDownStyles: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '& .react-select__control': {
        borderColor: Colors.GRAY_TWENTYTWO,
        borderWidth: 2,
        '&:hover': {
          borderColor: `${Colors.GRAY_TWENTYTWO} !important`,
        },
      },
      '& .MuiFormControl-root': {
        paddingRight: 20,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${Colors.GRAY_TWENTYTWO} !important`,
      },
    },

    deleteApplicationDialog: {
      '& .MuiDialog-paper': {
        width: 551,
        maxWidth: '100%',
        borderRadius: 25,
        padding: '50px 50px 43px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          borderRadius: 15,
          padding: '30px 16px 40px',
          margin: '10px !important',
        },

        '& .MuiTypography-h5': {
          fontWeight: 600,
          fontSize: 24,
          lineHeight: '32px',
          letterSpacing: '-0.019em',
          color: '#272929',
          marginBottom: 22,
          fontFamily: fonts.POPPINS_FONT,

          [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: '24px',
          },
        },

        '& .MuiDialogContent-root': {
          padding: 0,

          '& .MuiTypography-body1, li': {
            fontSize: 16,
            letterSpacing: '-0.011em',
            color: '#525454',
            fontFamily: fonts.INTER,

            [theme.breakpoints.down('xs')]: {
              fontSize: 14,
            },
          },

          '& .MuiTypography-body1': {
            marginBottom: 10,
          },

          '& ul': {
            margin: 0,

            '& li .MuiSvgIcon-root': {
              fontSize: 9,
            },
          },
        },

        '& .MuiDialogActions-root': {
          padding: '34px 0px 0px',
          justifyContent: 'flex-start',

          '& .MuiButton-root': {
            borderRadius: 12,
            fontFamily: fonts.POPPINS_FONT,
            fontWeight: 600,

            letterSpacing: '-0.006em',
            boxShadow: 'none',
            height: 40,
            textTransform: 'capitalize',

            '& .MuiButton-label': {
              fontSize: 14,
            },

            [theme.breakpoints.down('xs')]: {
              padding: 0,
            },
          },

          '& .MuiButton-outlined': {
            background: Colors.WHITE_ONE,
            border: '1px solid #D4D4D4',
            color: '#272929',
            minWidth: 194,
            marginLeft: 16,

            [theme.breakpoints.down('xs')]: {
              marginLeft: 8,
              minWidth: 149,
            },
          },

          '& .MuiButton-contained': {
            color: Colors.WHITE_ONE,
            background: '#E94444',
            minWidth: 151,

            [theme.breakpoints.down('xs')]: {
              minWidth: 110,
            },
          },
        },
      },
    },

    applicationAccordions: {
      '& .MuiAccordion-root': {
        border: 0,
        margin: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',

        '&:before': {
          display: 'none',
        },
      },

      '& .MuiDivider-root.accordionDivider': {
        backgroundColor: '#D4D4D4',
        margin: '15px 0px 43px',

        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },

      '& .MuiDivider-root.accordionDividerSpacing': {
        marginBottom: 0,
      },

      '& .MuiAccordionSummary-root': {
        padding: 0,
        minHeight: 'initial',
        justifyContent: 'flex-start',
      },

      '& .MuiAccordionSummary-content': {
        flexGrow: 'initial',
        margin: 0,
      },

      '& .MuiAccordionDetails-root': {
        padding: 0,
        flexDirection: 'column',
      },

      '& .accordionCollapseText': {
        '& .MuiTypography-h6': {
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',
          fontFamily: fonts.POPPINS_FONT,
        },

        '& .MuiIconButton-root': {
          padding: 0,
        },
      },

      '& .accordionReview, .accordionInProgress, .accordionInNotStarted, .accordionCompleted':
        {
          marginBottom: 15,

          [theme.breakpoints.down('xs')]: {
            margin: 0,
            padding: '29px 0px',
          },
        },

      '& .accordionStuck': {
        color: '#FDB515',

        [theme.breakpoints.down('xs')]: {
          margin: 0,
          padding: '0px 0px 29px',
        },

        '& .MuiSvgIcon-root': {
          color: '#FDB515',
        },
      },

      '& .accordionReview': {
        color: '#992FED',

        '& .MuiSvgIcon-root': {
          color: '#992FED',
        },
      },

      '& .accordionInProgress': {
        color: '#2F80ED',

        '& .MuiSvgIcon-root': {
          color: '#2F80ED',
        },
      },

      '& .accordionInNotStarted': {
        color: '#A9A9A9',

        '& .MuiSvgIcon-root': {
          color: '#A9A9A9',
        },
      },

      '& .accordionCompleted': {
        color: '#45CE93',

        '& .MuiSvgIcon-root': {
          color: '#45CE93',
        },
      },
    },

    applicationTableAccordion: {
      paddingLeft: 16,

      '& .MuiBox-root': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#272929',
        paddingBottom: 12,
      },
    },

    statusMobileDesign: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        padding: '19px 0px',
      },
    },

    accordionCollpaseParent: {
      borderRadius: 15,
      marginBottom: 9,
      border: '1px solid #D4D4D4',
      position: 'relative',

      '& .arrowButton': {
        [theme.breakpoints.down('sm')]: {
          position: 'absolute',
          top: 13,
          right: 5,
        },
      },

      '& .defaultStatus': {
        borderRadius: 15,
      },

      '& .stuck': {
        borderLeft: '15px solid #FDB515',
      },

      '& .reviewReady': {
        borderLeft: '15px solid #992FED',
      },

      '& .inProgress': {
        borderLeft: '15px solid #2F80ED',
      },

      '& .notStarted': {
        borderLeft: '15px solid #A9A9A9',
      },

      '& .complete': {
        borderLeft: '15px solid #45CE93',
      },

      '& .childBoxMobileWidth': {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: '0px 15px',
        },

        '&:last-child': {
          [theme.breakpoints.down('sm')]: {
            width: '10% !important',
            padding: 0,
          },
        },
      },

      '& .maxWord': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '61px !important',
          fontWeight: 600,
          fontSize: 14,
          color: '#7D7F7F',
          letterSpacing: '-0.014em',
          textTransform: 'uppercase',
          fontFamily: fonts.POPPINS_FONT,
        },
      },

      '& .schoolName': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '61px !important',
        },
      },

      '& .dateBox': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '61px !important',
        },
      },

      '& .schoolName, & .dateBox, & .maxWord': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#272929',
        fontFamily: fonts.INTER,

        [theme.breakpoints.down('xs')]: {
          color: '#7D7F7F',
        },
      },

      '& .childBox': {
        padding: '8px 0px',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: '0px 15px',
        },
      },

      '& .collegeAvatarGrid': {
        paddingLeft: 16,
        paddingRight: 16,

        '& .MuiTypography-body2': {
          fontFamily: fonts.INTER,
          fontWeight: 600,
          fontSize: 18,
          letterSpacing: '-0.014em',
          color: '#525454',
        },

        '& img': {
          marginRight: 31,

          [theme.breakpoints.down('md')]: {
            marginRight: 15,
          },
        },
      },

      '& .MuiCollapse-wrapperInner': {
        padding: '20px 0px 21px 75px',
        maxWidth: 1028,

        [theme.breakpoints.down('sm')]: {
          padding: '20px 0px 21px 20px',
        },

        [theme.breakpoints.down('xs')]: {
          padding: 15,
        },

        '& .MuiTypography-h6': {
          fontFamily: fonts.POPPINS_FONT,
          textTransform: 'uppercase',
          fontSize: 14,
          letterSpacing: '0.006em',
          color: '#7D7F7F',
          marginBottom: 7,
          fontWeight: 600,
        },

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#7D7F7F',
          whiteSpace: 'break-spaces',
        },
      },
    },

    // application detail page

    commonSelectDropdown: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },

      '& .MuiInputBase-root': {
        borderRadius: 6,
        padding: '0px 16px',
        width: 160,
        fontWeight: 600,
        fontSize: 14,
        color: '#A9A9A9',
        backgroundSize: 10,
        letterSpacing: '-0.006em',
        background: 'transparent',
        fontFamily: fonts.POPPINS_FONT,
        textTransform: 'uppercase',
        backgroundColor: '#F4F4F4',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 17px',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#A9A9A9',
        borderRadius: 6,
        borderWidth: 2,
        padding: '12px 0px',
      },

      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },

      '& .MuiInput-underline:after, .MuiInput-underline:before': {
        display: 'none',
      },

      '& .MuiSelect-icon': {
        display: 'none',
      },

      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '12px 0px',
        textTransform: 'uppercase',
      },
    },

    applicationHeaderWrapper: {
      borderBottom: '1px solid #D4D4D4',

      [theme.breakpoints.down('xs')]: {
        border: 0,
      },
    },

    applicationHeader: {
      padding: '68px 0px 35px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '30px 0px 26px',
        borderBottom: '1px solid #D4D4D4',
      },

      '& .MuiTypography-h3': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 36,
        letterSpacing: '-0.022em',
        color: '#272929',
        marginBottom: 30,
      },
    },

    selectsubCampus: {
      paddingBottom: 49,

      [theme.breakpoints.down('xs')]: {
        padding: '33px 0px 0px',
      },

      '& .MuiTypography-h5': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.022em',
        color: '#272929',
        marginBottom: 4,
      },

      '& .subCampusSubtitle': {
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        marginBottom: 20,
      },

      '& .MuiFormGroup-row': {
        maxWidth: 620,
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,
        marginRight: 30,

        [theme.breakpoints.down('sm')]: {
          width: '50%',
          marginRight: 0,
        },

        '& .MuiTypography-body1': {
          fontFamily: fonts.INTER,
          fontWeight: 400,
          fontSize: 16,
          letterSpacing: '-0.011em',
          color: '#525454',
        },
      },

      '& .MuiCheckbox-colorSecondary': {
        borderRadius: 5,

        '&.Mui-checked': {
          color: '#7D7F7F',
        },
      },

      '& .MuiCheckbox-root': {
        padding: 5,
      },
    },

    customDeadlineMenu: {
      width: '100%',

      '& .MuiSelect-root': {
        maxWidth: 210,
        marginLeft: 'auto',

        '& span:last-child': {
          display: 'block',
          width: 160,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

    addCommonEssay: {
      paddingTop: 48,

      [theme.breakpoints.down('xs')]: {
        paddingTop: 33,
      },

      '& .MuiTypography-h5': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',
        marginBottom: 16,

        [theme.breakpoints.down('xs')]: {
          fontSize: 20,
          marginBottom: 26,
        },
      },
    },

    commonDialogFeature: {
      '& .MuiDialog-paper': {
        maxWidth: 843,
        width: '100%',
        padding: '40px 30px 51px',
        margin: 0,
        borderRadius: 25,

        [theme.breakpoints.down('xs')]: {
          margin: '0px 10px !important',
          padding: '31px 16px',
        },
      },

      '& .MuiDialogContent-root': {
        padding: 0,
      },
    },

    dialogHeader: {
      paddingBottom: 8,

      [theme.breakpoints.down('xs')]: {},

      '& .MuiTypography-h5': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#272929',

        [theme.breakpoints.down('xs')]: {
          fontSize: 18,
          width: 225,
        },
      },

      '& .MuiIconButton-root': {
        [theme.breakpoints.down('xs')]: {
          opacity: '0.3',
          width: 12,
          padding: 0,
        },
      },

      '& .MuiTypography-caption': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#A9A9A9',

        [theme.breakpoints.down('xs')]: {
          fontSize: 14,
          fontWeight: 400,
          marginTop: 9,
          display: 'block',
        },
      },
    },

    dialogTableDocument: {
      borderSpacing: '0px 15px',
      borderCollapse: 'separate',

      '& thead': {
        '& tr': {
          '& th': {
            border: 0,
            fontFamily: fonts.POPPINS_FONT,
            fontWeight: 600,
            fontSize: 14,
            letterSpacing: '0.006em',
            textTransform: 'uppercase',
            color: '#A9A9A9',
            padding: '0px 16px',

            [theme.breakpoints.down('xs')]: {
              whiteSpace: 'nowrap',
            },
          },
        },
      },

      '& tbody': {
        '& tr': {
          '& td': {
            background: '#F4F4F4',
            border: 0,
            height: 55,

            [theme.breakpoints.down('xs')]: {
              whiteSpace: 'nowrap',
            },

            '&:first-child': {
              borderRadius: '13px 0px 0px 13px',
              padding: '8px 8px 8px 28px',
            },

            '&:last-child': {
              borderRadius: '0px 13px 13px 0px',
              padding: 8,
            },

            '&.essayType': {
              fontFamily: fonts.INTER,
              fontWeight: 600,
              fontSize: 18,
              letterSpacing: '-0.014em',
              color: '#272929',
            },

            '& a': {
              fontFamily: fonts.INTER,
              fontSize: 14,
              letterSpacing: '-0.006em',
              color: '#7D7F7F',
              textDecoration: 'underline !important',

              '& img': {
                marginRight: 11,
              },
            },
          },
        },
      },

      '& .MuiButton-contained': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 'bold',
        fontSize: 14,
        letterSpacing: '-0.006em',
        borderRadius: 12,
        color: Colors.WHITE_ONE,
        backgroundColor: '#2F80ED',
        padding: '8px 16px',
        minWidth: 187,
        height: 40,
        boxShadow: 'none',

        [theme.breakpoints.down('xs')]: {
          minWidth: 125,
          marginLeft: 15,
        },
      },
    },

    sectionSpacingBorder: {
      padding: '61px 0px 0px',

      [theme.breakpoints.down('xs')]: {
        padding: '33px 0px',
      },
    },

    headingDisplayMobile: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    applicationDetailPageTable: {
      padding: '0px 15px',

      '& .wordMaxMobile': {
        [theme.breakpoints.down('sm')]: {
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '-0.014em',
          color: '#A9A9A9',
          marginBottom: 14,
          fontFamily: fonts.POPPINS_FONT,
          textTransform: 'uppercase',
        },
      },

      '& .requiredCheckMobile': {
        '& .MuiTypography-body2': {
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '-0.014em',
          color: '#A9A9A9',
          fontFamily: fonts.POPPINS_FONT,
          textTransform: 'uppercase',
        },

        '& img': {
          marginRight: 8,
        },
      },

      '& .essayTableHeading': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '0.018em',
        textTransform: 'uppercase',
        color: '#272929',
        paddingBottom: 12,
      },

      '& .essayTableHeadingFirst': {
        width: '15%',
        "& [class*='MuiSelect-root'],& [class*='MuiOutlinedInput-input']": {
          color: 'inherit !important',
        },

        [theme.breakpoints.down('md')]: {
          width: '20%',
        },

        [theme.breakpoints.down('sm')]: {
          width: '50%',
        },

        '& .MuiSelect-root': {
          fontFamily: fonts.POPPINS_FONT,
        },
      },

      '& .essayTableHeadingSecond': {
        width: '25%',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          margin: '16px 0px 6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      },

      '& .essayTableHeadingThird': {
        width: '10%',

        [theme.breakpoints.down('sm')]: {
          width: '50%',
        },
      },

      '& .essayTableHeadingFour': {
        width: '10%',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },

      '& .essayTableHeadingFive': {
        width: '25%',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginBottom: 11,
        },
      },

      '& .essayTableHeadingSix': {
        width: '5%',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          textAlign: 'center',
        },
      },

      '& .essayName': {
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#7D7F7F',
      },

      '& .changeButton': {
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        marginLeft: 19,
        textTransform: 'uppercase',
        textDecorationLine: 'underline !important',
        cursor: 'pointer',
      },

      '& .requiredFileNot': {
        color: 'red',
      },

      '& .MuiCollapse-wrapperInner': {
        padding: '33px 0px 32px 215px',

        [theme.breakpoints.down('sm')]: {
          padding: '33px 0px 32px 192px',
        },

        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },

        '& .MuiTypography-h6': {
          fontSize: 14,
          fontWeight: 600,
          color: '#7D7F7F',
          fontFamily: fonts.POPPINS_FONT,
          letterSpacing: '0.006em',
          textTransform: 'uppercase',
          marginBottom: 7,
        },

        '& .MuiTypography-body1': {
          fontSize: 14,
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.006em',
          maxWidth: 1028,
        },
      },
    },

    applicationTableStyle: {
      background: '#F4F4F4',
      borderRadius: 13,
      marginBottom: 18,
      padding: '8px 15px',

      [theme.breakpoints.down('sm')]: {
        padding: '18px 16px',
      },

      '& .removeEllipsis': {
        '& .MuiSelect-selectMenu': {
          textOverflow: 'inherit !important',
        },
      },

      '& .addGoogleDriveLink': {
        '& .MuiButton-label': {
          fontSize: 14,
          color: '#2F80ED',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.006em',
          textTransform: 'capitalize',
          fontWeight: 'normal',
        },

        '& img': {
          marginRight: '16px !important',
        },
      },

      '& a': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        textDecoration: 'underline !important',

        '& img': {
          marginRight: 11,
        },
      },
    },

    fileActionButtons: {
      marginLeft: 16,

      '& .MuiIconButton-root': {
        width: 30,
        height: 30,
        padding: 5,
      },
    },

    headingBorderMobile: {
      borderTop: '1px solid #D4D4D4',
      paddingTop: 33,
    },

    otherEssaysSection: {
      margin: '0px 0px 48px',
      padding: '48px 0px',
      borderBottom: '1px solid #D4D4D4',

      [theme.breakpoints.down('xs')]: {
        padding: '0px 0px 25px',
        margin: '33px 0px',
      },
    },

    applicationEssayHeading: {
      fontFamily: fonts.POPPINS_FONT,
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.019em',
      color: '#272929',
    },

    applicationToDoList: {
      display: 'flex',
      alignItems: 'center',
      background: '#F4F4F4',
      borderRadius: 13,
      padding: '13px 24px',
      marginTop: 19,
      position: 'relative',

      [theme.breakpoints.down('xs')]: {
        padding: 14,
      },
    },

    editDeleteBox: {
      marginLeft: 25,
      width: '100%',

      '& .MuiTypography-h6': {
        fontSize: 16,
        fontWeight: 400,
        color: '#272929',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
        lineHeight: 'initial',
      },

      '& .addNote': {
        fontSize: 12,
        color: '#7D7F7F',
        cursor: 'pointer',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.011em',
      },

      '& .noteAdded': {
        letterSpacing: '-0.006em',
      },
    },

    dialogTableDocumentMobile: {
      background: '#F4F4F4',
      borderRadius: 15,
      padding: '20px 16px 28px',
      marginBottom: 17,

      '& .essayType': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#525454',
        fontFamily: fonts.INTER,
        marginBottom: 20,
      },

      '& .MuiButton-contained': {
        width: 110,
        height: 32,
        borderRadius: 12,
        marginTop: 29,
        fontWeight: 600,
        fontSize: 14,
        color: '#FFFFFF',
        background: '#2F80ED',
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        boxShadow: 'none',
      },

      '& a': {
        fontFamily: fonts.INTER,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
        textDecoration: 'underline !important',

        '& img': {
          marginRight: 11,
        },
      },
    },

    userNameApplication: {
      marginBottom: 33,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .MuiTypography-h3': {
        margin: 0,

        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },

        [theme.breakpoints.down('xs')]: {
          fontSize: 25,
          marginTop: 15,
        },
      },

      '& .applicationAvatar': {
        width: 36,
        height: 36,
        borderRadius: '50%',
        backgroundColor: '#d4d4d4',
        marginRight: 16,
        overflow: 'hidden',

        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        },
      },
    },
    forLinkText: {
      '& a': {
        color: 'inherit',
        display: 'flex',
        '&:hover': {
          color: 'inherit',
        },
      },
    },
  })
);
