import moment from 'moment';
import { useState, FC, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Box,
  Checkbox,
  Grid,
  Accordion,
  AccordionDetails,
  Hidden,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useStyles } from './MyStudentsStyle';
import { customApplicationTableSorting } from './static';

import MULTIPLE_SORTING_ICON from '../../../img/multiple-sorting.svg';
import ARROW_DOWN from '../../../img/ArrowDown.svg';
import ARROW_UP from '../../../img/ArrowUp.svg';

import { StudentDetailQuery_Student_studentApplications } from './__generated__/StudentDetailQuery';
import { Link } from 'react-router-dom';

import { useStylesTable } from './tableCommonStyle';
import COLLEGE_DEFAULT_IMG from '../../../img/college-default.svg';
import ChangeApplicationStatus from './ChangeApplicationStatus';
import { StudentApplicationsProps } from '../../../common/interfaces';
import { disableDropDown } from '../../../common/utility';
import { CustomTooltip } from '../../../common/CommonComponents';
import { useCommonStyles } from '../../../common/commonStyles';
import Toast from '../../../components/Toast';
import Drawer from '../../../components/addStudentApplication/colleges/CustomDrawer';
import MenuApplications from './MenuApplications';
import gql from 'graphql-tag';
import client from '../../../apollo';
import Loader from '../../../components/Loader';

// mutation gql for BSMD
export const SET_BSMD_STATUS = gql`
  mutation setBsmdStatusMutation($id: Int!) {
    setBsmdStatus(id: $id) {
      success
    }
  }
`;

// query gql for BSMD
export const GET_BSMD_STATUS = gql`
  query getBsmdStatusQuery($studentId: Int!) {
    getBsmdStatus(studentId: $studentId) {
      id,
      isBSMD
    }
  }
`;

const StudentApplicationsTable: FC<StudentApplicationsProps> = ({
  applications,
  title,
  user,
  refetch,
  highSchoolYear,
  studentId,
  gapYear = false,
  graduated = false,
}) => {
  const classes = useStyles();
  const dropStyle = useCommonStyles();
  const [status, setStatus] = useState('AllApplications');
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [sortColumn, setSortColumn] = useState<{
    column: string;
    sortDesc: boolean | 'default';
  }>({
    column: 'dueDate',
    sortDesc: false,
  });
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // state for BSMD loader
  const [bsmdLoader, setBSMDLoader] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  customApplicationTableSorting(applications, sortColumn);

  const handleSortColumn = (column: string) => {
    const newSortDescVal =
      column !== sortColumn?.column
        ? false
        : sortColumn?.sortDesc === true
          ? 'default'
          : sortColumn?.sortDesc === 'default'
            ? false
            : !sortColumn?.sortDesc;

    setSortColumn({
      column,
      sortDesc: newSortDescVal,
    });
  };

  const handleChangeAccordion =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const columnSelectedStyle = (column: string) => {
    return sortColumn.column === column && sortColumn.sortDesc !== 'default'
      ? 'selectedColumn'
      : '';
  };

  const getSortColumnIcon = (column: string) => {
    return sortColumn.column === column && sortColumn.sortDesc === false
      ? ARROW_UP
      : sortColumn.column === column && sortColumn.sortDesc === true
        ? ARROW_DOWN
        : MULTIPLE_SORTING_ICON;
  };

  let studentCollegeApplications:
    | (StudentDetailQuery_Student_studentApplications | null)[]
    | null;
  if (status === 'Completed') {
    studentCollegeApplications =
      applications?.filter((item) => item?.completedAt) || [];
  } else studentCollegeApplications = applications;
  const tableClasses = useStylesTable();
  const applicationStatuses = [
    { key: 'notStarted', value: 'Not Started' },
    { key: 'Completed', value: 'Completed' },
    { key: 'inProgress', value: 'In Progress' },
  ];
  const decisions = [
    {
      display: 'Early Decision',
      value: 'EarlyDecision',
    },
    {
      display: 'Early Action',
      value: 'EarlyAction',
    },
    {
      display: 'Regular Decision',
      value: 'RegularDecision',
    },
  ];
  const handleNotificationPopup = (message: string) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const handleNotificationClose = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  // stated for handling checkbox and bsmd status data
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [bsmdStatusArray, setBsmdStatusArray] = useState<(any | null)[]>([]);

  // mutation handler for BSMD
  const handleSetBsmdStatus = async (
    checked: boolean,
    id: number | undefined
  ) => {
    try {
      // change loading state to true
      setBSMDLoader(true);

      const response = await client.mutate({
        mutation: SET_BSMD_STATUS,
        variables: {
          id,
        },
      });

      // change the checked status based on the previous state
      setIsChecked(isChecked => !isChecked);

      // set toast on success
      if (response) {
        setOpenSnackbar(true);
        setSnackbarMessage('BSMD Status Updated Successfully');
      }

    } catch (error) {
      // set loading state to false
      setBSMDLoader(false);
      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  }

  // query handler for BSMD
  const handleGetBsmdStatus = async () => {
    try {
      // change loading state to true
      setBSMDLoader(true);

      const response = await client.query({
        query: GET_BSMD_STATUS,
        variables: {
          studentId,
        },
        fetchPolicy: "network-only"
      });

      const bsmdArray: (any | null)[] = [];

      if (response) {
        response?.data?.getBsmdStatus?.map((object: any, index: number) => {
          const appId: number = object?.id;
          const bsmdStatus: boolean = object?.isBSMD;

          bsmdArray[appId] = bsmdStatus;
        })
        setBsmdStatusArray(bsmdArray);
      } else {
        // set loading state to false
        setBSMDLoader(false);

        return false;
      }

      // set loading state to false
      setBSMDLoader(false);

    } catch (error) {
      // set loading state to false
      setBSMDLoader(false);

      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  }
  // get data on every reload/ checkbox clicked/ url change
  useEffect(() => {
    handleGetBsmdStatus()
  }, [isChecked, window.location.href]);

  return (
    <>
      {/* loader */}
      {bsmdLoader && <Loader />}
      <Toast
        open={openSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        close={handleNotificationClose}
        type="success"
      />
      <Grid className={`${classes.commonTable} ${classes.applicationTable}`}>
        <Grid className="pb-5">
          <Accordion
            className={classes.tableAccordion}
            expanded={expanded === 'panel1'}
            onChange={handleChangeAccordion('panel1')}
          >
            <AccordionDetails className={classes.accordionDirection}>
              <Hidden only={['xl', 'lg', 'md']}>
                <Grid className={classes.mobileStatusSorting}>
                  <Typography variant="body1">View</Typography>
                  <FormControl variant="outlined">
                    <Select
                      value={status}
                      onChange={handleChange}
                      label="View"
                      placeholder="Status"
                    >
                      <MenuItem value={'AllApplications'}>
                        All Applications
                      </MenuItem>
                      <MenuItem value={'Completed'}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Hidden>
              {applications && applications?.length ? (
                <Grid>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <Hidden only={['xl', 'lg', 'md']}>
                          <TableCell width="50%"></TableCell>
                        </Hidden>

                        <Hidden only={['sm', 'xs']}>
                          <TableCell width="30%"></TableCell>
                        </Hidden>

                        <Hidden only={['sm', 'xs']}>
                          <TableCell
                            width="15%"
                            onClick={() => handleSortColumn('acceptance')}
                            className={`acceptanceCol sortingColumn ${columnSelectedStyle(
                              'acceptance'
                            )} `}
                          >
                            Acceptance
                            <img
                              src={getSortColumnIcon('acceptance')}
                              alt="sort"
                            />
                          </TableCell>
                          <TableCell width="15%">Type</TableCell>
                          {applications && applications?.length ? (
                            <TableCell
                              onClick={() => handleSortColumn('dueDate')}
                              width="15%"
                              className={`dueDateCol sortingColumn ${columnSelectedStyle(
                                'dueDate'
                              )} `}
                            >
                              Due Date
                              <img
                                src={getSortColumnIcon('dueDate')}
                                alt="sort"
                              />
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </Hidden>
                        <Hidden only={['xl', 'lg', 'md']}>
                          {applications && applications?.length ? (
                            <TableCell width="30%">Due Date</TableCell>
                          ) : (
                            <></>
                          )}
                        </Hidden>

                        <Hidden only={['sm', 'xs']}>
                          <TableCell width="15%">
                            <Box>
                              Status
                              <CustomTooltip
                                className="pl-2"
                                title="Track your Student's results here."
                              />
                            </Box>
                          </TableCell>
                          {/* BSMD column */}
                          <TableCell width="10%">
                            <Box>
                              BSMD
                              <CustomTooltip
                                className="pl-2"
                                title="Please check the checkbox if BSMD application"
                              />
                            </Box>
                          </TableCell>
                        </Hidden>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {studentCollegeApplications &&
                        studentCollegeApplications?.length ? (
                        studentCollegeApplications?.map((item, index) => {
                          return (
                            <TableRow
                              key={
                                item?.applicationRequirement?.college?.name ||
                                index
                              }
                            >
                              <TableCell>
                                {item?.studentApplicationCampuses &&
                                  item?.studentApplicationCampuses?.length > 0 ? (
                                  <>
                                    <Link
                                      to={`/students/application/${item?.id}`}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        className={
                                          tableClasses.collegeAvatarName
                                        }
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          className="collegeLogo"
                                        >
                                          <img
                                            src={
                                              item?.applicationRequirement
                                                ?.college?.parentMetaData
                                                ?.logo || COLLEGE_DEFAULT_IMG
                                            }
                                            alt="avatar"
                                          />
                                        </Box>

                                        <Typography variant="body1">
                                          {
                                            item?.applicationRequirement
                                              ?.college?.parentMetaData?.name
                                          }
                                        </Typography>
                                      </Box>
                                    </Link>

                                    <Grid className={classes.childBranches}>
                                      {item?.studentApplicationCampuses?.map(
                                        (campus: any) => (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            width="100%"
                                            className="collegeAvatarName"
                                          >
                                            <Link
                                              to={`/students/application/${item?.id}?isMain=false&campus=${campus?.id}`}
                                            >
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <img
                                                  width="26px"
                                                  src={
                                                    campus?.logo ||
                                                    COLLEGE_DEFAULT_IMG
                                                  }
                                                  alt="avatar"
                                                />
                                                <Typography variant="body2">
                                                  {campus.name?.split('-')[1]}
                                                </Typography>
                                              </Box>
                                            </Link>
                                            <Typography
                                              variant="body2"
                                              className="subCampusPercentage"
                                            >
                                              {Math.round(
                                                campus?.applicationRequirement
                                                  ?.acceptRate * 100
                                              )}
                                              %
                                            </Typography>
                                          </Box>
                                        )
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  <Link
                                    to={`/students/application/${item?.id}`}
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      className={tableClasses.collegeAvatarName}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        className="collegeLogo"
                                      >
                                        <img
                                          src={
                                            item?.applicationRequirement
                                              ?.college?.logo ||
                                            COLLEGE_DEFAULT_IMG
                                          }
                                          alt="college"
                                        />
                                      </Box>
                                      <Typography variant="body1">
                                        {
                                          item?.applicationRequirement?.college
                                            ?.name
                                        }
                                      </Typography>
                                    </Box>
                                  </Link>
                                )}
                              </TableCell>

                              <Hidden only={['sm', 'xs']}>
                                <TableCell
                                  style={{
                                    verticalAlign: 'baseline',
                                    padding: '12px 8px 3px',
                                  }}
                                >
                                  {item?.studentApplicationCampuses &&
                                    item?.studentApplicationCampuses?.length <
                                    1 &&
                                    `${Math.round(
                                      (item?.applicationRequirement
                                        ?.acceptRate || 0) * 100
                                    )}%`}
                                </TableCell>
                                <TableCell
                                  style={{
                                    verticalAlign: 'baseline',
                                    padding: '12px 8px 3px',
                                  }}
                                >
                                  {decisions.find(
                                    (decision) =>
                                      item?.decisionStatus === decision.value
                                  )?.display ||
                                    item?.decisionStatus ||
                                    'Deadline Unavailable'}
                                </TableCell>
                              </Hidden>

                              <TableCell
                                style={{
                                  verticalAlign: 'baseline',
                                  padding: '12px 8px 3px',
                                }}
                              >
                                {item?.decisionDeadline
                                  ? moment
                                    .utc(item?.decisionDeadline)
                                    .format('MM/DD/YYYY')
                                  : '--'}
                              </TableCell>

                              <Hidden only={['sm', 'xs']}>
                                {item?.studentApplicationCampuses &&
                                  item?.studentApplicationCampuses.length > 0 ? (
                                  <TableCell
                                    className={`
                                    ${dropStyle.decisionSelect}
                                    ${dropStyle.dropStyleTable}
                                  `}
                                    style={{
                                      verticalAlign: 'baseline',
                                    }}
                                  >
                                    {/* This Section will appear if University Campuses Are Available  */}
                                    <>
                                      {disableDropDown(
                                        item?.decisionDeadline,
                                        highSchoolYear || ''
                                      ) ||
                                        (!!!item?.decisionStatus &&
                                          !!!item?.decisionDeadline) ||
                                        item?.decision ? (
                                        <>
                                          <Box
                                            className={`${dropStyle.campusesDropdown}`}
                                          >
                                            {item?.studentApplicationCampuses?.map(
                                              (campus: any) => (
                                                <Box
                                                  className={
                                                    dropStyle.campusesDropDownSpacing
                                                  }
                                                >
                                                  <ChangeApplicationStatus
                                                    handleNotificationPopup={
                                                      handleNotificationPopup
                                                    }
                                                    setLoading={setLoading}
                                                    loading={loading}
                                                    appId={item?.id}
                                                    collegeId={campus?.id}
                                                    userId={user?.id}
                                                    campusesRow={
                                                      item?.ApplicationCampusesSurvey
                                                    }
                                                    refetch={refetch}
                                                  />
                                                </Box>
                                              )
                                            )}
                                          </Box>
                                        </>
                                      ) : (
                                        <Box
                                          className={`applicationStatus ${dropStyle.campusesStatusSpacing
                                            }
                                                ${item.applicationStatus ===
                                              'In Progress'
                                              ? 'inProgress'
                                              : item.applicationStatus ===
                                                'Completed'
                                                ? 'Completed'
                                                : 'notStarted'
                                            }`}
                                        >
                                          {
                                            applicationStatuses.find(
                                              (status) =>
                                                status.key ===
                                                item?.applicationStatus
                                            )?.value
                                          }
                                        </Box>
                                      )}
                                    </>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className={`  
                                    ${dropStyle.decisionSelect}
                                      ${dropStyle.dropStyleTable}`}
                                  >
                                    {/* This Section will appear if University Campuses Are Not Available */}
                                    {disableDropDown(
                                      item?.decisionDeadline,
                                      highSchoolYear || ''
                                    ) ||
                                      (!!!item?.decisionStatus &&
                                        !!!item?.decisionDeadline) ||
                                      item?.decision ? (
                                      <ChangeApplicationStatus
                                        handleNotificationPopup={
                                          handleNotificationPopup
                                        }
                                        loading={loading}
                                        setLoading={setLoading}
                                        appId={item?.id}
                                        appDecision={item?.decision}
                                        userId={user?.id}
                                        refetch={refetch}
                                      />
                                    ) : (
                                      <Box
                                        className={`applicationStatus ${item?.applicationStatus} === 'In Progress'
                                              ? 'inProgress'
                                              : ${item?.applicationStatus} === 'Completed'
                                                ? 'Completed'
                                                : 'notStarted'
                                                `}
                                      >
                                        {
                                          applicationStatuses.find(
                                            (status) =>
                                              status.key ===
                                              item?.applicationStatus
                                          )?.value
                                        }
                                      </Box>
                                    )}
                                  </TableCell>
                                )}
                                {/* BSMD checkbox cell */}
                                <TableCell
                                  style={{
                                    verticalAlign: 'baseline',
                                  }}
                                >
                                  {
                                    item?.id && bsmdStatusArray[item?.id] ?
                                      <Checkbox
                                        checked={bsmdStatusArray[item?.id]}
                                        onChange={(e) => {
                                          handleSetBsmdStatus(e.target.checked, item?.id);
                                        }}
                                        inputProps={{ 'aria-label': 'bsmd checkbox' }}
                                        color="primary"
                                      />
                                      :
                                      <Checkbox
                                        checked={false}
                                        onChange={(e) => {
                                          handleSetBsmdStatus(e.target.checked, item?.id);
                                        }}
                                        inputProps={{ 'aria-label': 'bsmd checkbox' }}
                                      />
                                  }
                                </TableCell>
                                <TableCell
                                  style={{
                                    verticalAlign: 'baseline',
                                    padding: '12px 8px 3px',
                                  }}
                                >
                                  <MenuApplications
                                    row={item}
                                    studentId={studentId}
                                    refetch={refetch}
                                    setRefetchLoading={setLoading}
                                    gapYear={gapYear}
                                    graduated={graduated}
                                    highSchoolYear={highSchoolYear || ''}
                                  />
                                </TableCell>
                              </Hidden>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className="noRecordFound"
                            align="center"
                          >
                            No applications found!
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              ) : (
                <Box className={classes.emptyStates}>
                  <Typography variant="body1">No applications found</Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
          <Drawer refetch={refetch} studentId={studentId} />
        </Grid>
      </Grid>
    </>
  );
};

export default StudentApplicationsTable;
